.preloader-dot-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0px auto;
    width: 97px;
    }
    .preloader-dot-loading:after {
      content: attr(data-name);
      position: absolute;
      top: 45%;
      left: 0;
      height: 10px;
      width: 100%;
      text-align: center;color: #333;
    }

    [class^="loader-img"]{position:fixed;top:50%;left:50%;opacity:0;}
    .loader-img1{transform: translate(-50%, -50%) rotate(20deg) scale(0.25);animation: load1 6s linear infinite;}
    .loader-img2{transform: translate(-50%, -50%) rotate(0deg) scale(0.25);animation: load2 6s linear infinite 1s;}
    .loader-img3{transform: translate(-50%, -50%) rotate(20deg) scale(0.25);animation: load1 6s linear infinite 2s;}
    .loader-img4{transform: translate(-50%, -50%) rotate(0deg) scale(0.25);animation: load2 6s linear infinite 3s;}
    .loader-img5{transform: translate(-50%, -50%) rotate(20deg) scale(0.25);animation: load1 6s linear infinite 4s;}
    .loader-img6{transform: translate(-50%, -50%) rotate(20deg) scale(0.25);animation: load2 6s linear infinite 5s;}

    @keyframes load1{
      0%{transform: translate(-50%, -50%) rotate(0deg) scale(0.1);opacity:1;}
      45%{opacity:0.2;}
      65%{opacity:0;}
      85%{opacity:0;}
      100%{transform: translate(-50%, -50%) rotate(0deg) scale(8);opacity:0;}
    }
    @keyframes load2{
      0%{transform: translate(-50%, -50%) rotate(20deg) scale(0.1);opacity:1;}
      45%{opacity:0.2;}
      65%{opacity:0;}
      85%{opacity:0;}
      100%{transform: translate(-50%, -50%) rotate(20deg) scale(8);opacity:0;}
    }
    
    .loader-img1 svg path{animation:strokew 6s linear infinite}
    .loader-img2 svg path{animation:strokew 6s linear infinite 1s}
    .loader-img3 svg path{animation:strokew 6s linear infinite 2s}
    .loader-img4 svg path{animation:strokew 6s linear infinite 3s}
    .loader-img5 svg path{animation:strokew 6s linear infinite 4s}
    .loader-img6 svg path{animation:strokew 6s linear infinite 5s}
    @keyframes strokew{
      0%{stroke-width: 3;}
      25%{stroke-width: 1;}
      50%{stroke-width: 0.2;}
    }
  