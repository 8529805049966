
@font-face {
    font-family: 'Archivo';
    src: url('Archivo-Thin.ttf');
    src: local('Archivo Thin'), local('Archivo-Thin'),
    url('Archivo-Thin.ttf');
    font-weight: 100;
}

@font-face {
    font-family: 'Archivo';
    src: url('Archivo-ExtraLight.ttf');
    src: local('Archivo Extra Light'), local('Archivo-ExtraLight'),
    url('Archivo-ExtraLight.ttf');
    font-weight: 200;
}

@font-face {
    font-family: 'Archivo';
    src: url('Archivo-Light.ttf');
    src: local('Archivo Light'), local('Archivo-Light'),
    url('Archivo-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Archivo';
    src: url('Archivo-Regular.ttf');
    src: local('Archivo Regular'), local('Archivo-Regular'),
    url('Archivo-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Archivo';
    src: url('Archivo-Medium.ttf');
    src: local('Archivo Medium'), local('Archivo-Medium'),
    url('Archivo-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Archivo';
    src: url('Archivo-SemiBold.ttf');
    src: local('Archivo Semibold'), local('Archivo-Semibold'),
    url('Archivo-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Archivo';
    src: url('Archivo-Bold.ttf');
    src: local('Archivo Bold'), local('Archivo-Bold'),
    url('Archivo-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Archivo';
    src: url('Archivo-ExtraBold.ttf');
    src: local('Archivo Extra Bold'), local('Archivo-ExtraBold'),
    url('Archivo-ExtraBold.ttf');
    font-weight: 800;
    font-style: italic;
}